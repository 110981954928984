//@author: mandy

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { SearchOptionsComponent } from "./search.options.component";
import { S25CheckboxModule } from "../../s25-checkbox/s25.checkbox.module";

@NgModule({
    declarations: [SearchOptionsComponent],
    imports: [CommonModule, FormsModule, S25CheckboxModule],
    providers: [SearchOptionsComponent],
    exports: [SearchOptionsComponent],
})
export class SearchOptionsModule {
    constructor() {}
}
