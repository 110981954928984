//@author: mandy

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { ResourceSettingsComponent } from "./resource.settings.component";
import { SearchOptionsModule } from "../search-options/search.options.module";

@NgModule({
    declarations: [ResourceSettingsComponent],
    imports: [CommonModule, FormsModule, SearchOptionsModule],
    providers: [ResourceSettingsComponent],
})
export class ResourceSettingsModule {
    constructor() {}
}
