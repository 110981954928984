//@author: mandy

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { S25WorkflowComponent } from "./s25.workflow.component";
import { S25ToggleButtonModule } from "../../s25-toggle-button/s25.toggle.button.module";

@NgModule({
    declarations: [S25WorkflowComponent],
    imports: [CommonModule, FormsModule, S25ToggleButtonModule],
    providers: [S25WorkflowComponent],
    exports: [S25WorkflowComponent],
})
export class S25WorkflowModule {
    constructor() {}
}
